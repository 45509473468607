import type { MouseEventHandler } from 'react';
import { useCallback, useContext } from 'react';
import { MenuAlt1Icon } from '@heroicons/react/outline';

import { SidePanelContext } from '../contexts/SidePanelStateContext';

export default function EmptyResults() {
  const [_, setSidebarOpen] = useContext(SidePanelContext);

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setSidebarOpen(true);
  }, [setSidebarOpen]);

  return (
    <button
      className="relative mx-auto block w-full max-w-md rounded-lg border-2 border-dashed border-gray-300 p-12 text-center"
      onClick={onClick}
    >
      <MenuAlt1Icon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">No Results</h3>
      <p className="mt-1 text-sm text-gray-500">
        Select one or more clinical findings
        <br />
        (at least one in the mouth).
      </p>
    </button>
  );
}
