import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

export default function MarkdownToHtml({ children }: { children: string }) {
  return (
    // <div className="prose prose-rose">
    <ReactMarkdown remarkPlugins={[gfm]}>{children}</ReactMarkdown>
    // </div>
  );
}
