import { createContext, useEffect, useState } from 'react';
import type { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

export type MatchRule = {
  diagnosis: string;
  signName: string;
  matchRule: 'expected' | 'supportive';
};

export type Disorder = {
  diagnosis: string;
  geneAssociation?: string;
  generalFindings?: string;
  linkOrphanet?: string;
  nameAndCodeOrphanet?: string;
  oralFindings?: string;
  prevalence?: string;
  synonymsEnglish: string;
};

export class Diagnosis {
  geneAssociation?: string;
  generalFindings?: string;
  linkOrphanet?: string;
  name: string;
  nameAndCodeOrphanet?: string;
  oralFindings?: string;
  prevalence?: string;
  synonymsEnglish?: string[];

  // for the matching
  expected: Set<string>;
  supportives: Set<string>;
  matchExpected = 0;
  matchSupportives = 0;

  constructor(matchRule: MatchRule) {
    this.name = matchRule.diagnosis;
    this.expected = new Set<string>();
    this.supportives = new Set<string>();
    this.addRule(matchRule);
  }

  addRule(rule: MatchRule) {
    switch (rule.matchRule) {
      case 'expected':
        this.expected.add(rule.signName);
        break;
      case 'supportive':
        this.supportives.add(rule.signName);
        break;
      default:
        throw new Error('Unknown match rule');
    }
  }

  addSupportive(supportive: string) {
    this.supportives.add(supportive);
  }

  addExpected(expected: string) {
    this.expected.add(expected);
  }

  get ratioExpected() {
    return this.matchExpected / this.expected.size;
  }

  set description(description: Diagnosis) {
    this.synonymsEnglish = description.synonymsEnglish.split(',');
    this.prevalence = description.prevalence;
    this.geneAssociation = description.geneAssociation;
    this.nameAndCodeOrphanet = description.nameAndCodeOrphanet;
    this.linkOrphanet = description.linkOrphanet;
    this.generalFindings = description.generalFindings;
    this.oralFindings = description.oralFindings;
  }

  toString() {
    return this.name;
  }

  matchSearch(query: string) {
    return (
      this.name.toLowerCase().includes(query.toLowerCase()) ||
      this.nameAndCodeOrphanet?.toLowerCase().includes(query.toLowerCase()) ||
      this.synonymsEnglish?.some(
        (synonym) =>
          synonym.toLowerCase().includes(query.toLowerCase()) ||
          this.geneAssociation?.toLowerCase().startsWith(query.toLowerCase()),
      )
    );
  }

  static compare(a: Diagnosis, b: Diagnosis) {
    // first compare matching expected
    const match = b.matchExpected - a.matchExpected;
    if (match !== 0) {
      return match;
    }
    // then compare ration expected/total expected
    const rationExpected = b.ratioExpected - a.ratioExpected;
    if (rationExpected !== 0) {
      return rationExpected;
    }
    // finally compare matching supportives
    return b.matchSupportives - a.matchSupportives;
  }

  static compareName(a: Diagnosis, b: Diagnosis) {
    return a.name.localeCompare(b.name);
  }
}

interface DiagnosesProviderProps {}

type DiagnosesContextValue = {
  diagnoses: Diagnosis[];
};

const DiagnosesContext = createContext<DiagnosesContextValue>({
  diagnoses: [],
});

function DiagnosesProvider({
  children,
}: PropsWithChildren<DiagnosesProviderProps>) {
  const [diagnoses, setDiagnoses] = useState<Diagnosis[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      Promise.all([
        fetch('/diagnoses.json', {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        fetch('/matchRules.json', {
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
      ])
        .then(([diasgnosesResponse, rulesResponse]) => {
          if (!diasgnosesResponse.ok || !rulesResponse.ok) {
            navigate('/404');
          }
          return Promise.all([diasgnosesResponse.json(), rulesResponse.json()]);
        })
        .then(([diagnoses, matchRules]: [Disorder[], MatchRule[]]) => {
          const rulesMap = new Map<string, Diagnosis>();

          for (const rule of matchRules) {
            if (rulesMap.has(rule.diagnosis)) {
              rulesMap.get(rule.diagnosis)?.addRule(rule);
            } else {
              rulesMap.set(rule.diagnosis, new Diagnosis(rule));
            }
          }

          for (const disorder of diagnoses) {
            if (rulesMap.has(disorder.diagnosis)) {
              rulesMap.get(disorder.diagnosis).description = disorder;
            }
          }
          setDiagnoses(Array.from(rulesMap.values()));
        });
    };

    fetchData();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DiagnosesContext.Provider value={{ diagnoses }}>
      {children}
    </DiagnosesContext.Provider>
  );
}

export { DiagnosesContext, DiagnosesProvider };
