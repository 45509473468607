import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';

import Button from '../layouts/Button';

type Props = {
  onClick: MouseEventHandler;
};

export default function ResetButton({ onClick }: Props) {
  const [_searchParams, setSearchParams] = useSearchParams();

  const onClickButton: MouseEventHandler = useCallback(
    (event) => {
      setSearchParams({});
      if (onClick) {
        onClick(event);
      }
    },
    [onClick, setSearchParams],
  );

  return (
    <Button type="reset" onClick={onClickButton} variant="primary">
      <PlusIcon
        className="-ml-1 mr-2 hidden h-5 w-5 sm:block"
        aria-hidden="true"
      />
      New search
    </Button>
  );
}
