import { useCallback, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SidePanelContext } from '../contexts/SidePanelStateContext';
import Diagnoses from '../components/Diagnoses';
import Signs from './Signs';
import SidePanel from './SidePanel';

export default function Home() {
  const [isOpen, setIsOpen] = useContext(SidePanelContext);
  const [searchParams] = useSearchParams();

  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  useEffect(() => {
    if (searchParams.get('signs') === null) {
      setIsOpen(true);
    }
  }, [searchParams, setIsOpen]);

  return (
    <>
      <Diagnoses openMenu={openMenu} />
      <SidePanel
        title="Clinical findings"
        openPanel={setIsOpen}
        isOpen={isOpen}
      >
        <p className="text-medium py-2 text-gray-600">
          Select at least one clinical finding in the oral cavity to produce a list of potential diagnoses.
        </p>
        <Signs />
      </SidePanel>
    </>
  );
}
