import { ChevronRightIcon } from '@heroicons/react/outline';
import { Disclosure, Transition } from '@headlessui/react';

import Chip from '../Chip';
import DiagnosisDetails from '../DiagnosisDetails';
import type { Diagnosis } from '../../contexts/DiagnosesContext';

type Props = {
  diagnosis: Diagnosis;
  showChips: boolean;
};

export default function Disorder({ diagnosis, showChips = false }: Props) {
  return (
    <Disclosure as="li" className="group flex-1">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
            <div className="flex flex-shrink-0 space-x-4">
              <ChevronRightIcon
                className={` ${
                  open ? 'rotate-90' : 'rotate-0'
                } h-4 w-4 transition delay-100 ease-in-out`}
                aria-hidden="true"
              />
              <p className="text-grey-600 overflow-hidden truncate text-ellipsis text-sm font-medium">
                {diagnosis.name}
              </p>
            </div>
            <div className="ml-2 flex flex-shrink-0">
              <div className={showChips ? 'sm:flex' : 'hidden'}>
                <Chip variant="success">
                  <div className="hidden text-xs text-gray-500 md:mr-1 md:flex">
                    Expected
                  </div>
                  {diagnosis?.matchExpected}/{diagnosis?.expected.size}
                </Chip>
                <Chip variant="warning">
                  <div className="hidden text-xs text-gray-500 md:mr-1 md:flex">
                    Supportive
                  </div>
                  {diagnosis?.matchSupportives}
                </Chip>
              </div>
            </div>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="h-0 opacity-0"
            enterTo="h-fit opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="h-fit opacity-100"
            leaveTo="h-0 opacity-0"
          >
            <Disclosure.Panel static as="div" className="py-2 px-14">
              <DiagnosisDetails showChips={showChips} {...diagnosis} />
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
