import type { InputHTMLAttributes } from 'react';
import { useId } from 'react';

type Props = {
  dataList: string[];
} & InputHTMLAttributes<HTMLInputElement>;

export default function Input({ id, dataList = [], ...props }: Props) {
  const fallbackId = useId();
  return (
    <>
      <input
        className="block w-full rounded-md border-gray-300 pl-10 focus:border-rose-500 focus:ring-rose-500 sm:text-sm"
        {...props}
        list={`list-${id || fallbackId}`}
      />
      <datalist id={`list-${id || fallbackId}`}>
        {dataList.map((item) => (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <option key={`list-${id || fallbackId}-${item}`} value={item} />
        ))}
      </datalist>
    </>
  );
}
