import { useContext } from 'react';

import { SignsContext } from '../contexts/SignsContext';
import Tabs from '../layouts/Tabs';
import AreaSigns from '../layouts/AreaSigns';
import map from '../utils/map';

export type Category = {
  name: string;
  subCategories: string[];
};

export default function Signs() {
  const { signsByCategory } = useContext(SignsContext);

  return (
    <Tabs
      tabs={map(
        signsByCategory.entries(),
        ([category, signsBySubcategories]) => ({
          name: category,
          content: (
            <AreaSigns
              category={category}
              key={category}
              symptomSubCategories={signsBySubcategories}
            />
          ),
        }),
      )}
    />
  );
}
