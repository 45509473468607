import classNames from 'classnames';
import type { PropsWithChildren } from 'react';

type Props = {
  variant?: 'success' | 'warning';
};

export default function Chip({ children, variant }: PropsWithChildren<Props>) {
  return (
    <span
      className={classNames(
        {
          'bg-green-100 text-green-800': variant === 'success',
          'bg-yellow-100 text-yellow-800': variant === 'warning',
        },
        'ml-2 inline-flex items-center space-x-2 rounded-full px-2 text-xs font-semibold leading-5',
      )}
    >
      {children}
    </span>
  );
}
