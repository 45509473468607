import { PencilIcon } from '@heroicons/react/outline';

import Button from './Button';
import Diagnoses from './Diagnoses';
import Layout from './Layout';
import EmptyResults from './EmptyResults';
import ResetButton from '../components/ResetButton';
import type { Diagnosis } from '../contexts/DiagnosesContext';

type Props = {
  diagnoses: Diagnosis[];
  editSearch: () => void;
};

export default function Results({ diagnoses, editSearch }: Props) {
  return (
    <Layout
      header={
        <>
          <div className="flex-1 p-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Potential diagnoses
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {diagnoses.length} results
            </p>
          </div>
          <div className="ml-4 flex items-center space-x-2 md:ml-6 md:space-x-4">
            <Button type="button" onClick={editSearch}>
              <PencilIcon
                className="-ml-1 mr-2 hidden h-5 w-5 sm:block"
                aria-hidden="true"
              />
              Edit search
            </Button>
            <ResetButton onClick={editSearch} />
          </div>
        </>
      }
    >
      <div className="min-h-full flex-1 bg-gray-100 md:px-6 md:py-4">
        {diagnoses.length ? (
          <div className="mx-auto min-h-full max-w-7xl flex-1 overflow-hidden bg-white md:rounded-md md:shadow">
            <Diagnoses showChips diagnoses={diagnoses} />
          </div>
        ) : (
          <EmptyResults />
        )}
      </div>
    </Layout>
  );
}
