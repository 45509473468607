import { Fragment, useContext } from 'react';

import type { Diagnosis } from '../contexts/DiagnosesContext';
import { SignsContext } from '../contexts/SignsContext';
import map from '../utils/map';
import Anchor from './Anchor';
import Chip from './Chip';
import MarkdownToHtml from './MarkdownToHtml';
import Sign from './Sign';

// function ResouceLink({ title, link }: { title: string; link?: string }) {
//   return (
//     <li className="flex items-center justify-between py-1 text-sm">
//       {link && link.length > 0 ? (
//         <Anchor href={link} className="w-0 flex-1 truncate">
//           {title}
//         </Anchor>
//       ) : (
//         <span className=" w-0 flex-1 truncate">{title}</span>
//       )}
//     </li>
//   );
// }

export default function DiagnosisDetails({
  // name,
  expected,
  geneAssociation,
  generalFindings,
  linkOrphanet,
  matchExpected,
  matchSupportives,
  nameAndCodeOrphanet,
  oralFindings,
  prevalence,
  supportives,
  synonymsEnglish,
  showChips = false,
}: Diagnosis & { showChips?: boolean }) {
  const { signs, categories } = useContext(SignsContext);

  // lookup map to know in wich category the sign belongs
  const categoriesBySigns = new Map<string, string>(
    signs.map(({ name, category }) => [name, category]),
  );

  function renderSign(signs: Set<string>) {
    const categorisedSigns = new Map<string, JSX.Element[]>(
      map(categories.keys(), (category) => [category, []]),
    );
    signs.forEach((sign) => {
      const category = categoriesBySigns.get(sign);
      if (category) {
        categorisedSigns.set(category, [
          ...(categorisedSigns.get(category) as JSX.Element[]),
          <Sign key={sign} disabled name={sign} />,
        ]);
      }
    });
    return map(categorisedSigns.entries(), ([category, signs]) =>
      signs.length ? (
        <Fragment key={category}>
          <dt className="mt-2 text-xs text-gray-500">{category}</dt>
          <dd className="mt-1">{signs}</dd>
        </Fragment>
      ) : (
        ''
      ),
    );
  }

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          Expected clinical findings
          {showChips && (
            <Chip variant="success">
              {matchExpected}/{expected.size}
            </Chip>
          )}
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          <ul>{renderSign(expected)}</ul>
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          Supportive clinical findings
          {showChips && (
            <Chip variant="warning">
              {matchSupportives}/{supportives.size}
            </Chip>
          )}
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          <ul>{renderSign(supportives)}</ul>
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Synonyms</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {synonymsEnglish?.join(', ')}
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Summary</dt>
        <dd className="mt-1 text-sm text-gray-900">
          <MarkdownToHtml>{generalFindings}</MarkdownToHtml>
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Oral aspects</dt>
        <dd className="mt-1 text-sm text-gray-900">
          <MarkdownToHtml>{oralFindings}</MarkdownToHtml>
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Gene association</dt>
        <dd className="mt-1 text-sm text-gray-900">{geneAssociation}</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Prevalence</dt>
        <dd className="mt-1 text-sm text-gray-900">{prevalence}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">
          Resources and further reading
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          <ul>
            {linkOrphanet && linkOrphanet.length > 0 && (
              <li className="flex items-center justify-between py-1 text-sm">
                {linkOrphanet?.startsWith('http') ? (
                  <>
                    <p>Orphanet:</p>&nbsp;
                    <Anchor href={linkOrphanet} className="w-0 flex-1 truncate">
                      {nameAndCodeOrphanet}
                    </Anchor>
                  </>
                ) : (
                  <span className=" w-0 flex-1 truncate">{linkOrphanet}</span>
                )}
              </li>
            )}
            {/* {resources('resource')} */}
          </ul>
        </dd>
      </div>
      {/* <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Rights in Norway</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {resources('rights')}</ul>
        </dd>
      </div> */}
    </dl>
  );
}
