import type { PropsWithChildren } from 'react';

export default function Anchor({
  children,
  className,
  ...props
}: PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>) {
  return (
    <a
      {...props}
      className={'text-rose-500 hover:underline hover:opacity-75 '.concat(
        className,
      )}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}
