import { Disclosure, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';

import List from './List';
import SignCheckBox from './Sign';
import classNames from '../utils/classNames';
import type { Sign } from '../contexts/SignsContext';

type AreaSignsProps = {
  category: string;
  symptomSubCategories?: Map<string, Sign[]>;
};

export default function AreaSigns({
  category,
  symptomSubCategories,
}: AreaSignsProps) {
  const subCategories = () => {
    const subCategories: JSX.Element[] = [];
    if (symptomSubCategories) {
      symptomSubCategories.forEach((signs, subCategory) => {
        subCategories.push(
          <Disclosure as="div" className="block pt-4" key={subCategory}>
            {({ open }) => (
              <>
                <Disclosure.Button
                  as="dt"
                  className="flex flex-1 items-center space-x-2 bg-white text-left text-gray-400"
                >
                  <ChevronRightIcon
                    className={classNames(
                      open ? 'rotate-90' : 'rotate-0',
                      'h-5 w-5 transform transition duration-75 ease-out',
                    )}
                    aria-hidden="true"
                  />
                  <p className="font-medium text-gray-900">{subCategory}</p>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform h-o opacity-0"
                  enterTo="transform h-max opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform h-max opacity-100"
                  leaveTo="transform h-o opacity-0"
                >
                  <Disclosure.Panel as="dd" className="pt-2 pb-4 pr-12">
                    <List>
                      {signs.map((symptom) => (
                        <List.Item
                          className="py-4"
                          key={`${category}-${category}-${symptom.name}`}
                        >
                          <SignCheckBox {...symptom} />
                        </List.Item>
                      ))}
                    </List>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>,
        );
      });
    }
    return subCategories;
  };

  return <dl className="min-h-max">{subCategories()}</dl>;
}
