export type PagesLink = {
  title: string;
  link: string;
};

export const pages: PagesLink[] = [
  {
    title: 'Disorder Search',
    link: '/',
  },
  {
    title: 'Directory of Disorders',
    link: '/directory',
  },
  {
    title: 'FAQ',
    link: '/faq',
  },
];
