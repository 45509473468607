import { BeakerIcon } from '@heroicons/react/outline';
import { NavLink } from 'react-router-dom';

import { pages } from '../../navigation';
import NksdLogo from './nksd_logo.png';
import OtherLogo from './other_logo.png';

export default function Sidebar() {
  return (
    <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
        <div className="flex flex-shrink-0 items-center px-4">
          <NavLink to="/" className="flex flex-shrink-0 items-center">
            <BeakerIcon className="h-8 w-8 text-rose-500" />
            <span className="text-medium ml-4">
              The Unique Teeth
              <br /> Database
            </span>
          </NavLink>
        </div>
        <div className="mt-5 flex flex-grow flex-col">
          <nav className="flex-1 space-y-1 px-2 pb-4">
            {pages.map(({ title, link }) => (
              <NavLink
                end
                key={title}
                to={link}
                className={({ isActive }) =>
                  `${
                    isActive
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  } 'group font-medium' flex items-center rounded-md py-2 px-2 text-sm`
                }
              >
                {title}
              </NavLink>
            ))}
          </nav>
        </div>
        <div className="flex flex-shrink-0 p-4">
          <div className="mt-8 space-y-3 md:order-1 md:mt-0">
            <p className="text-sm text-gray-400">Published by</p>
            <div className="mt-2 h-12 w-full">
              <span className="sr-only">
                NKSD - Norwegian National Advisory Unit on Rare Disorders
              </span>
              <img
                alt="NKSD - Norwegian National Advisory Unit on Rare Disorders"
                src={NksdLogo}
                className="h-full w-full object-contain"
              />
            </div>
            {/* remove hidden */}
            <div className="mt-2 hidden h-12 w-full">
              <span className="sr-only">Description for second logo</span>
              <img
                alt="Description for second logo"
                src={OtherLogo}
                className="h-full w-full object-contain"
              />
            </div>
            <p className=" mt-4 text-sm text-gray-400">
              &copy; 2022 Papperslöst AB
              <br />
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
